import Stack from "../../../../../../shared/ui/stack/Stack";
import styles from './ItemHeaderTopWithMenu.module.scss';

const ItemHeaderTopWithMenu = ({ title, titleKey, onClick }) => {

	return (
		<Stack>
			<a role='menuitem' 
			className={styles.titleItem} 
			href={`#${titleKey}`}
			onClick={onClick}>
				{title}
			</a>
		</Stack>
	)
}

export default ItemHeaderTopWithMenu
