import Stack from "../../../../shared/ui/stack/Stack";
import styles from "./ContactButton.module.scss";

const ContactButton = ({ Icon, link, label }) => {

  return (
    <Stack className={styles.contactButton}>
      <a
        className={styles.contactButtonIcon}
        href={link} target="_blank" rel="noopener noreferrer"
      >
        <Icon />
      </a>

      <a
        className={styles.contactButtonLabel}
        href={link} target="_blank" rel="noopener noreferrer"
      >
        {label}
      </a>
    </Stack>
  );
};

export default ContactButton;
