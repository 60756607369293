import { useSelector } from 'react-redux';
import styles from './Reviews.module.scss';
import Stack from '../../../../shared/ui/stack/Stack';
import { Slider } from '../slider/Slider';
import SectionTitle from '../../../../shared/ui/sectionTitle/SectionTitle';
import { reviewsData } from '../../lib/data';
import { useTranslatedData } from '../../../../app/translation/lib/useTranslatedData';
import { SliderDesktop } from '../sliderDesktop/SliderDesktop';
import { getReviewsArray } from '../../model/reduxSlice/reviewsSlice';

export const Reviews = () => {
    const data = useTranslatedData();
    const reviewsArray = useSelector(getReviewsArray);
    const reviews = reviewsArray.length > 0 ? reviewsArray : reviewsData;

    return (
        <Stack
            id="clientReviews"
            justify='alignCenter'
            direction="column"
        >
            <SectionTitle text={data.clientReviews} />
            <div className={styles.slider}>
                <Slider reviews={reviews} />
            </div>
            <div className={styles.desktopSlider}>
                <SliderDesktop reviews={reviews} />
            </div>
        </Stack>
    );
}