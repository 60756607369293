import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getAllReviews } from '../../model/reduxSlice/reviewsSlice';
import './customSlickStyles.scss';
import styles from './SliderDesktop.module.scss';
import Slider from "react-slick";
import classNames from 'classnames';

export const SliderDesktop = ({reviews}) => {
    const dispatch = useDispatch();

    const [slideIndex, setSlideIndex] = useState(0);

    useEffect(() => {
        dispatch(getAllReviews());
    }, [dispatch]);

    const settings = {
        className: "center",
        centerMode: true,
        infinite: true,
        centerPadding: "0",
        slidesToShow: 3,
        speed: 500,
        dots: true,
        beforeChange: (current, next) => setSlideIndex(next),
        autoplay: true,
        autoplaySpeed: 7000
    };

    return (
        <div className={styles.slickSliderContainer}>
            <Slider {...settings}>
                {reviews?.map((img, i) => (
                    <div className={classNames(styles.sliderItemContainer, `${i !== slideIndex ? styles.slickSideSlide : ""}`)} key={i}>
                        <div className={classNames(styles.slickSliderItem, `${i === slideIndex ? styles.slickActive : ""}`)} >
                            <img src={img._id ? `${process.env.REACT_APP_BASE_URL}/uploads/${img.imageUrl}` : `${process.env.PUBLIC_URL}/images/reviews/${img.path}`}
                                alt={`Slide ${i + 1}`}
                                loading="lazy"
                            />
                        </div>
                    </div>
                ))}
            </Slider>
        </div>
    );
}