import MainPage from "./pages/mainPage/MainPage";
import { useCheckAndResetLocalStorage } from "./shared/hooks/useCheckAndResetLocalStorage";

function App() {
	useCheckAndResetLocalStorage();
	
	return (
		<MainPage />
	);
};

export default App;
