import NavBarItem from "../navbarItems/NavbarItem";
import styles from "./Navbar.module.scss";
import { data, crossSvg } from "../../lib/data";
import Stack from "../../../../shared/ui/stack/Stack";
// import useChangeLanguage from "../../../../app/translation/lib/useChangeLanguage";
import openCalendlyModal from "../../../../features/сalendly/get-termin";
import { useState } from "react";
import SlideMenu from "../menu/SlideMenu";

const Navbar = () => {
  // const changeLanguage = useChangeLanguage();

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  // const [languageChanged, setLanguageChanged] = useState(false);

  const handleButtonClick = () => {
    openCalendlyModal(
      // "https://calendly.com/gnedyshevaeg/mortgage-consultation"
      "https://calendly.com/czech-mortgage/consultation?fbclid=PAZXh0bgNhZW0CMTEAAaaTgREPw1a6x-gWckUCDv1xSAPUomOsEUz1gm2gzi0X84khL3t1FQXrSEw_aem_AETjJSyAGCEcrUfJINoFHg&month=2024-07"
    );
  };

  const actions = {
    '#home': () => window.scrollTo(0, 0),
    '#bookNow': handleButtonClick,
    // '#lang': () => {
		// 	changeLanguage()
		// 	setLanguageChanged(prev => !prev)
		// },
    '#menu': () => setIsMenuOpen((prev) => !prev),
  };



  const handleClick = (e) => {
    e.preventDefault();
    const actionName = e.currentTarget.hash;
    if (actionName && actions[actionName]) {
      actions[actionName]();
    } 
  };

  const handleCloseMenu = () => {
    setIsMenuOpen(false);
  };

  const modifiedData = data.map(item => {
    if (item.title === 'menu') {
      return {
        ...item,
        svg: isMenuOpen ? crossSvg : item.svg,
        action: "menu",
      };
    }
    return { ...item, action: item.title };
  });

  // useEffect(() => {}, [languageChanged]);

  return (
    <>
      <Stack>
        <SlideMenu isOpen={isMenuOpen} onClose={handleCloseMenu} />
      </Stack>

      <Stack className={styles.wrapper} direction="column">
        {/* <nav className={styles.nav} key={languageChanged} role="navigation" aria-label='sections'> */}
        <nav className={styles.nav} role="navigation" aria-label='sections'>
          {modifiedData.map((item) => (
            <NavBarItem
              item={item}
              key={item.title}
              onClick={handleClick}
            />
          ))}
        </nav>
      </Stack>
    </>
  );
};

export default Navbar;


