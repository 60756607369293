import { useEffect } from "react";
import packageJson from '../../../package.json';

const CURRENT_VERSION = packageJson.version;

export const useCheckAndResetLocalStorage = () => {
    useEffect(() => {
        const storedVersion = localStorage.getItem('appVersion');

        if (storedVersion !== CURRENT_VERSION) {
            localStorage.clear();

            localStorage.setItem('appVersion', CURRENT_VERSION);
        }
    }, []);
};