import styles from "./HeaderTopWithMenu.module.scss";
import Logo from "../../../../../../shared/ui/logo/Logo";
import Stack from "../../../../../../shared/ui/stack/Stack";
// import useChangeLanguage from "../../../../../../app/translation/lib/useChangeLanguage";
import ItemHeaderTopWithMenu from "../itemHeaderTopWithMenu/ItemHeaderTopWithMenu";
// import { data } from "../../../../../../entities/navbar/lib/data";
import { useTranslatedData } from "../../../../../../app/translation/lib/useTranslatedData";
import openCalendlyModal from "../../../../../../features/сalendly/get-termin";

function HeaderTopWithMenu() {
	const dataTranslate = useTranslatedData()
	// const language = dataTranslate.language
	const headerItems = dataTranslate.titleHeaderMenu
	// const svgLanguage = data.find(item => item.title === 'language')
	// const changeLanguage = useChangeLanguage()

	const handleButtonClick = () => {
		openCalendlyModal(
		  // "https://calendly.com/gnedyshevaeg/mortgage-consultation"
		"https://calendly.com/czech-mortgage/consultation?fbclid=PAZXh0bgNhZW0CMTEAAaaTgREPw1a6x-gWckUCDv1xSAPUomOsEUz1gm2gzi0X84khL3t1FQXrSEw_aem_AETjJSyAGCEcrUfJINoFHg&month=2024-07"
		);
	};

	const handleClick = (e) => {
		const actionName = e.currentTarget.hash;
		if (actionName === '#bookNow') {
			e.preventDefault();
			handleButtonClick();
		}
	};
	return (
		<Stack className={styles.headerTopWithMenuContainer}>
			<Stack className={styles.headerTopWithMenu} justify='justifyBetween'>
				<Stack className={styles.titleItems}>
					{/* <button onClick={changeLanguage} aria-label='change the language'>{svgLanguage.svg[language]}</button> */}
					<ul
						className={styles.titleList}
						role='menu'
						aria-orientation='horizontal'
					>
						{Object.keys(headerItems).map(key => {
							return (
								<ItemHeaderTopWithMenu
									key={key}
									titleKey={key}
									title={headerItems[key]}
									onClick={handleClick}
								/>
							)
						})}
					</ul>
				</Stack>
				<Logo />
			</Stack>
		</Stack>
	)
}

export default HeaderTopWithMenu
