import styles from "./ContactsBlock.module.scss";
import Stack from "../../../../shared/ui/stack/Stack";
import ContactButton from "../contactButton/ContactButton";
import allSocialIcons from "../../lib/allSocialIcons";
import useWindowSize from "../../../../shared/hooks/useWindowSize";
import { useTranslatedData } from "../../../../app/translation/lib/useTranslatedData";

const ContactsBlock = () => {
  const data = useTranslatedData();

  const screenSize = useWindowSize();
  const tabletL = 1024;

  const address = 'V Celnici 1031/4, 110 00, Praha 1, Czech Republic';
  const licenseText = 'Spotřebitelské úvěry zprostředkovávám jako vázaný zástupce zapsaný v registru ČNB pod IČO:06607322 registrovaný pod samostatným zprostředkovatelem spotřebitelských úvěrů Broker Trust,a.s., se sídlem Hanusova 1411/18, 140 00 Praha 4, IČO: 26439719, zapsaná v obchodním rejstříkusp. zn. B 7141 vedená u Městského soudu v Praze.';

  return (
    <Stack className={styles.contactsBlock} direction='column'>

      <Stack direction='column'>
        <h3>{data.office}</h3>
        <p className={styles.contactsText}>{address}</p>

        <h3>{data.contactsFooter}</h3>
        <Stack className={styles.contactsIconsBlock} >
          {allSocialIcons.map(({ name, label, component: Icon, link }) => (
            ((name === "Phone") || (name === "Email")) && <ContactButton key={name} Icon={Icon} link={link} label={label} />
          ))}
        </Stack>
      </Stack>

      <Stack className={styles.contactsIconsBlock}>
        {allSocialIcons.map(({ name, label, component: Icon, link }) => (
          (name !== "Phone") &&
          (name !== "Email") && <ContactButton key={name} Icon={Icon} link={link} label={screenSize.width > tabletL ? label : ""} />
        ))}
      </Stack>

      <p className={styles.licenseText}>
        {licenseText}
      </p>
    </Stack>
  );
};

export default ContactsBlock;
