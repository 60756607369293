import { useEffect, useState, useCallback } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import Button from '../../../../shared/ui/button/Button';
import Stack from '../../../../shared/ui/stack/Stack';
import Input from '../../../../shared/ui/input/Input';
import SectionTitle from '../../../../shared/ui/sectionTitle/SectionTitle';
import styles from './auth.module.scss';
import { loginAdmin, resetAdminPassword, removeAdmin, getErrorMessage, getAdmin } from '../../model/reduxSlice/adminSlice';
import Loader from '../../../../shared/ui/loader/Loader';


const Auth = () => {
    const methods = useForm({ mode: 'onChange' });
    const { register, handleSubmit, setValue, reset, formState: { errors } } = methods;
    const [formType, setFormType] = useState('');
    const dispatch = useDispatch();
    const errorMessage = useSelector(getErrorMessage);
    const admin = useSelector(getAdmin);
    const savedToken = localStorage.getItem('adminToken');
    const [isLoading, setIsLoading] = useState(false);
    const [showPassword, setShowPassword] = useState(false);

    useEffect(() => {
        if (savedToken) {
            setFormType('loggedIn');
        }
    }, [savedToken]);

    const handleLoginSubmit = (data, e) => {
        if (e?.key === 'Enter') {
            e.preventDefault(); 
        }
        setIsLoading(true)
        dispatch(loginAdmin(data))
            .finally(() => {
                setIsLoading(false);
            });
    };

    const handleLoginSuccess = useCallback(() => {
        reset({ login: "", password: "" });
        localStorage.setItem('adminToken', admin.token);
        setFormType('loggedIn');
        setIsLoading(false)
    }, [admin, reset]);

    const handleResetPasswordSubmit = (data) => {
        setIsLoading(true)
        dispatch(resetAdminPassword(data.email)).then((action) => {
            if (resetAdminPassword.fulfilled.match(action)) {
                setFormType('checkEmail');
            } setIsLoading(false)
        });
    };

    const handleLogout = () => {
        localStorage.removeItem('adminToken');
        dispatch(removeAdmin());
        setFormType('');
    };

    useEffect(() => {
        if (admin && admin.token) {
            handleLoginSuccess();
        }
    }, [admin, handleLoginSuccess]);

    const getSubmitHandler = () => {
        switch (formType) {
            case "login":
                return handleLoginSubmit;
            case "loggedIn":
                return handleLogout;
            case "resetPassword":
                return handleResetPasswordSubmit;
            default:
                return handleLoginSubmit;
        }
    };

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    }

    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.key === 'Enter' && formType === '') {
                event.preventDefault();
                document.querySelector(`button[type='submit']`)?.click();
            }
        };

        document.addEventListener('keydown', handleKeyDown);
        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [formType])

    return (
        <Stack direction="column" max>
            <SectionTitle text="Login to your account" />
            <FormProvider {...methods}>
                <form className={styles.loginForm} onSubmit={handleSubmit(getSubmitHandler())}>
                    {formType === '' && (
                        <>
                            {savedToken === null && errorMessage && (
                                <p className={styles.error}>{errorMessage.message}</p>
                            )}
                            <div>
                                <Input
                                    name="login"
                                    register={register}
                                    setValue={setValue}
                                    variant='form'
                                    placeholder="Your login"
                                    options={{ required: 'This field is required.' }}
                                    error={errors.login}
                                />
                            </div>

                            <div className={styles.password_container}>
                                <Input
                                    name="password"
                                    register={register}
                                    setValue={setValue}
                                    variant='form'
                                    placeholder="Your password"
                                    type={showPassword ? 'text' : 'password'}
                                    options={{ required: 'This field is required.' }}
                                    error={errors.password}
                                />
                                <button
                                    type="button"
                                    className={styles.passwordToggle}
                                    onClick={togglePasswordVisibility}>
                                        <img src={`/images/${showPassword ? 'hide.png' : 'show.png'}`} alt="eye password" />
                                </button>
                            </div>

                            <Button variant='transparent' onClick={() => setFormType('resetPassword')}>
                                Forgot your password?
                            </Button>
                            <Button type='submit'>
                                {isLoading ? <Loader width="15" /> : "Sign in"}
                            </Button>
                        </>
                    )}
                    {formType === 'resetPassword' && (
                        <>
                            <Input
                                name="email"
                                register={register}
                                options={{
                                    required: 'This field is required.',
                                    pattern: {
                                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                        message: 'Invalid email address format.'
                                    }
                                }}
                                setValue={setValue}
                                variant='form'
                                placeholder="Your email"
                                error={errors.email}
                            />
                            {errorMessage && <p className={styles.error}>{errorMessage.message}</p>}
                            <Button type='submit'>
                                {isLoading ? <Loader width="15" /> : "Reset Password"}
                            </Button>
                            <Button variant='transparent' onClick={() => setFormType('')}>
                                Back to Login
                            </Button>
                        </>
                    )}
                    {formType === 'checkEmail' && (
                        <>
                            <p>Check your email for reset instructions.</p>
                            <Button variant='transparent' onClick={() => setFormType('')}>
                                Back to Login
                            </Button>
                        </>
                    )}
                    {formType === 'loggedIn' && (
                        <Button onClick={handleLogout}>
                            {isLoading ? <Loader width="15" /> : "Logout"}
                        </Button>
                    )}
                </form>
            </FormProvider>
        </Stack>
    );
};

export default Auth;

